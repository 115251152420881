import { helper } from "lib/helper";
import post_modal from "models/post_modal";
import { useEffect, useState, useRef } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  Badge,
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Table,
  Container,
  Row,
  Input,
  Button,
  UncontrolledTooltip,
  Spinner,
} from "reactstrap";
import AddModal from "views/components/AddModal";
// core components
import TablePagination from "views/components/TablePagination";

import { Link } from "react-router-dom";
import parentsModal from "models/parents.modal";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import Avatar from "../../assets/img/Avatar.jpeg";
import Swal from "sweetalert2";

const Students = () => {
  const [modal, setModal] = useState(false);

  const toggleModal = (modalName) => {
    setModal(!modal);
  };

  const [isLoading, setIsLoading] = useState(true);
  const [dataList, setDataList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const parameters = {};
  for (let [key, value] of searchParams) {
    parameters[key] = value;
  }

  // Function to handle page change and update the current page number
  const handlePageChange = (curPage) => {
    setCurrentPage(curPage);
  };
  const storedUserInfo = JSON.parse(sessionStorage.getItem("userinfo"));

  console.log(storedUserInfo, "storedUserInfo");

  const [search, setSearch] = useState("");
  const getListData = async () => {
    const data = {
      email: storedUserInfo?.email,
      role: "admin",
      pageNo: currentPage,
      limit: 10,
      search: search,
    };
    setIsLoading(true);
    await parentsModal
      .AllUser(data, parameters)
      .then((result) => {
        setIsLoading(false);
        if (result) {
          setDataList(result);
          setCurrentPage(result?.meta?.current_page);
          setPerPage(result?.meta?.per_page);
        }
      })
      .catch((error) => {
        helper.sweetalert.toast(error.response?.data?.message, "warning");
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getListData();
  }, [location, currentPage, search]);

  // console.log(dataList, "dataListparentlist");

  const handleDel = async (delId) => {
    const data = {
      userEmail: delId,
    };
    Swal.fire({
      title: "Are you sure you want to delete?",
      icon: "warning",
      showCancelButton: true,
      showCloseButton: true,
      cancelButtonColor: "#6c757d",
      confirmButtonColor: "#dc3545",
      cancelButtonText: "Cancel",
      confirmButtonText: "Delete",
      allowOutsideClick: false,
      allowEscapeKey: false,
      reverseButtons: true,
      focusCancel: true,
      customClass: {
        header: "text-danger", // Adding custom class to header for cancel icon color
      },
      buttons: {
        cancel: {
          text: "Cancel",
          value: null, // Set value to null for default selection
        },
        confirm: {
          text: "Delete",
          value: true,
        },
      },
    }).then((result) => {
      if (result.isConfirmed) {
        parentsModal.UserDelete(data).then((res) => {
          helper.sweetalert.toast(res.msg);
          getListData();
        });
      }
    });
  };

  const tableRef = useRef(null);

  const downloadPDF = () => {
    const input = tableRef.current;

    if (!input) {
      console.error("Table element not found.");
      return;
    }

    html2canvas(input)
      .then((canvas) => {
        // Show action column again after capturing the table

        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF("p", "mm", "a4");
        const width = pdf.internal.pageSize.getWidth();
        const height = (canvas.height * width) / canvas.width;
        pdf.addImage(imgData, "PNG", 0, 0, width, height);
        pdf.save("Parents_list.pdf");
      })
      .catch((error) => {
        console.error("Error generating PDF:", error);
      });
  };

  console.log(dataList, "dataListuser");

  const parmas = useParams();

  console.log(parmas, "hhhhhhhhhhh");
  return (
    <>
      <Container className="pt-7">
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0 d-flex justify-content-between ">
                <h3 className="mb-0">All User </h3>

                <div className="d-flex flex-row">
                  {/* <i class="fa-solid fa-magnifying-glass"></i> */}
                  <Input
                    style={{ width: "290px" }}
                    className="mr-md-4 mb-2 mb-md-0 ml-md-4"
                    type="text"
                    placeholder="Search"
                    size="sm"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                  <Button
                    size="sm"
                    onClick={downloadPDF}
                    // style={{ width: "1x" }}
                  >
                    <i class="fa-solid fa-download"></i>
                  </Button>
                </div>
              </CardHeader>
              {isLoading ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Spinner></Spinner>
                </div>
              ) : (
                <div>
                  <div
                    ref={tableRef}
                    style={{ maxWidth: "100%", overflowX: "auto" }}
                  >
                    <Table
                      className="align-items-center table-flush"
                      responsive
                    >
                      <thead className="thead-light">
                        <tr>
                          <th scope="col">#</th>
                          <th scope="col">Profile Image</th>
                          <th scope="col"> user Name</th>
                          <th scope="col">School/Institution name</th>
                          <th scope="col">Gmail</th>
                          <th scope="col">Grade Level</th>
                          <th scope="col">Register Date</th>
                          <th scope="col">Action</th>
                        </tr>
                      </thead>

                      <tbody>
                        {dataList?.data?.length > 0 ? (
                          dataList?.data.map((item, index) => {
                            return (
                              <tr key={item._id} style={{ cursor: "pointer" }}>
                                <td>
                                  {(currentPage - 1) * perPage + index + 1}
                                </td>
                                {/* <td> <Link to={`/admin/student/parentsProfile/`}>{item?.user?.first_name || "-"}</Link></td> */}
                                <td className="p-0 pl-4">
                                  {" "}
                                  <img
                                    style={{
                                      width: "30px",
                                      borderRadius: "50%",
                                      height: "30px",
                                    }}
                                    // src={`https://cliply.co/wp-content/uploads/2020/09/442008571_ARTIST_AVATAR_3D_400px.gif`}
                                    src={item.image_path || Avatar}
                                    alt="profileimage"
                                  />
                                </td>
                                <td>
                                  {" "}
                                  <Link
                                    to={`/admin/student/parentsProfile/${item?.user?.id}/${item?.email}`}
                                  >
                                    {" "}
                                    {item?.first_name || "-"}{" "}
                                  </Link>
                                </td>
                                <td>{item?.school_name || "-"}</td>{" "}
                                <td>{item?.email || "-"}</td>
                                <td>{item?.grade_level || "-"}</td>
                                <td>{item?.created_at || "-"}</td>
                                <td
                                  className="p-0 pl-3"
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                  }}
                                >
                                  <UncontrolledTooltip
                                    placement="top"
                                    target="eyeTooltip"
                                  >
                                    View
                                  </UncontrolledTooltip>
                                  <Link
                                    style={{
                                      margin: "0 5px",
                                      fontSize: "15px",
                                      color: "blue",
                                      marginTop: "10px",
                                      display: "inline-block",
                                      // padding: "5px", // Optional: Add padding for better appearance
                                      borderRadius: "30%", // Optional: Make it a circle
                                    }}
                                    id="eyeTooltip"
                                    to={`/admin/student/parentsProfile/${item?.user?.id}/${item?.email}`}
                                    // tag={Link}
                                  >
                                    <i class="fa-solid fa-eye"></i>
                                  </Link>

                                  <UncontrolledTooltip
                                    placement="top"
                                    target="userTooltip"
                                  >
                                    Delete
                                  </UncontrolledTooltip>
                                  <p
                                    style={{
                                      margin: "0 8px",
                                      fontSize: "15px",
                                      color: "red",
                                      marginTop: "10px",
                                      // backgroundColor: "#f5f5f5",
                                      display: "inline-block",
                                      // padding: "5px", // Optional: Add padding for better appearance
                                      borderRadius: "20%", // Optional: Make it a circle
                                    }}
                                    onClick={() => handleDel(item?.email)}
                                    id="userTooltip"
                                  >
                                    <i className="fas fa-trash"></i>
                                  </p>
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr style={{ textAlign: "center" }}>
                            <td colSpan="6" style={{ verticalAlign: "middle" }}>
                              No data found
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </div>

                  <CardFooter className="py-4">
                    <TablePagination
                      totalPages={parseInt(dataList?.meta?.total_page)}
                      currentPage={parseInt(dataList?.meta?.current_page)}
                      pageUrl="/admin/user?"
                      onPageChange={handlePageChange}
                    />
                  </CardFooter>
                </div>
              )}
            </Card>
          </div>
        </Row>

        <AddModal
          modal={modal}
          Name="Add6"
          toggle={toggleModal}
          title="Add Fare management"
        />
      </Container>
    </>
  );
};

export default Students;
