/*eslint-disable*/
import { useState } from "react";
import { NavLink as NavLinkRRD, Link } from "react-router-dom";
// nodejs library to set properties for components
import { PropTypes } from "prop-types";

// reactstrap components
import {
  Collapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
  UncontrolledCollapse,
} from "reactstrap";

import UserProfileMenu from "components/UserProfileMenu";
import logoImg from '../../assets/img/logo.svg'

var ps;

const Sidebar = (props) => {
  const [collapseOpen, setCollapseOpen] = useState();
  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) => {
    return props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  };
  // toggles collapse between opened and closed (true/false)
  const toggleCollapse = () => {
    setCollapseOpen((data) => !data);
  };
  // closes the collapse

  const createLinks = (routes) => {
    if (!routes || !Array.isArray(routes) || routes.length === 0) {
      return null;
    }
    // const [openMenus, setOpenMenus] = useState({});

    // const toggleMenu = (name) => {
    //   setOpenMenus({
    //     ...openMenus,
    //     [name]: !openMenus[name],
    //   });
    // };

    return routes.map((prop, key) => {
      if (prop && prop.IsVisible) {
        const [isOpen, setIsOpen] = useState(false); // State for each dropdown

        const toggle = () => setIsOpen((prev) => !prev); // Toggle function for each dropdown

        return (
          <NavItem key={key}>
            <NavLink
              to={prop.layout + prop.path}
              tag={NavLinkRRD}
              onClick={() => {
                if (prop.children) {
                  toggle(); // Toggle the dropdown when clicked
                }
              }}
              activeClassName="activeNavLink"
            >
              <i className={prop.icon} />
              {prop.name}

              {prop.children && (
                <i
                  className={`fa-solid fa-caret-${isOpen ?  "up" :"down"} ml-5`}
                  style={{
                    color: "gray",
                    marginLeft: "auto",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    toggle(); 
                  }}
                ></i>
              )}
            </NavLink>

            {prop.children && isOpen && (
              <Collapse isOpen={isOpen}>
                <NavItem
                  component="div"
                  style={{
                    paddingLeft: "1rem",
                    display: "flex",
                    flexDirection: "column",
                    // width:"100%"
                  }}
                >
                  {prop.children.map((child, index) => (
                    <div key={index}>
                      <NavLink
                        to={prop.layout + child.path}
                        tag={Link}
                        activeClassName="activeNavLink"
                        style={{backgroundColor:"#f5f5f5",color:"black"}}
                      >
                        <i className={child.icon} />
                        {child.name}
                      </NavLink>
                    </div>
                  ))}
                </NavItem>
              </Collapse>
            )}
          </NavItem>
        );
      } else {
        return null;
      }
    });
  };

  const { bgColor, routes, logo } = props;
  let navbarBrandProps;
  if (logo && logo.innerLink) {
    navbarBrandProps = {
      to: logo.innerLink,
      tag: Link,
    };
  } else if (logo && logo.outterLink) {
    navbarBrandProps = {
      href: logo.outterLink,
      target: "_blank",
    };
  }
  const handleLogout = async (e) => {
    e.preventDefault();
    sessionStorage.clear();
    window.location.assign("/auth/login");
  };

  return (
    <Navbar
      className="navbar-vertical fixed-left navbar-light bg-white"
      expand="md"
      id="sidenav-main"
    >
      <Container fluid>
        {/* Toggler */}
        <button
          className="navbar-toggler"
          type="button"
          onClick={toggleCollapse}
        >
          <span className="navbar-toggler-icon" />
        </button>

        {/* Brand */}
        {logo ? (
          <NavbarBrand className="pt-0" {...navbarBrandProps}>
            <img
              alt={'Skill Linkers logo'}
              className="navbar-brand-img"
              src={logoImg}
              style={{ maxHeight: "75px" }}
            />
          </NavbarBrand>
        ) : null}
        {/* User */}
        <Nav className="align-items-center d-md-none">
          <UserProfileMenu />
        </Nav>
        {/* Collapse */}
        <Collapse navbar isOpen={collapseOpen}>
          {/* Collapse header */}
          <div className="navbar-collapse-header d-md-none">
            <Row>
              {logo ? (
                <Col className="collapse-brand" xs="6">
                  {logo.innerLink ? (
                    <Link to={logo.innerLink}>
                      <img alt={logo.imgAlt} src={logo.imgSrc} />
                    </Link>
                  ) : (
                    <a href={logo.outterLink}>
                      <img alt={logo.imgAlt} src={logo.imgSrc} />
                    </a>
                  )}
                </Col>
              ) : null}
              <Col className="collapse-close" xs="6">
                <button
                  className="navbar-toggler"
                  type="button"
                  onClick={toggleCollapse}
                >
                  <span />
                  <span />
                </button>
              </Col>
            </Row>
          </div>

          {/* Navigation */}
          <Nav navbar>{createLinks(routes)}</Nav>
          {/* Divider */}
          <hr className="my-3" />
          {/* Navigation */}
          <Nav className="mb-md-3" navbar>
            <NavItem>
              <NavLink onClick={handleLogout} style={{ cursor: "pointer" }}>
                <i className="fa-solid fa-right-from-bracket" />
                Logout
              </NavLink>
            </NavItem>
          </Nav>
        </Collapse>
      </Container>
    </Navbar>
  );
};

Sidebar.defaultProps = {
  routes: [{}],
};

Sidebar.propTypes = {
  // links that will be displayed inside the component
  routes: PropTypes.arrayOf(PropTypes.object),
  logo: PropTypes.shape({
    // innerLink is for links that will direct the user within the app
    // it will be rendered as <Link to="...">...</Link> tag
    innerLink: PropTypes.string,
    // outterLink is for links that will direct the user outside the app
    // it will be rendered as simple <a href="...">...</a> tag
    outterLink: PropTypes.string,
    // the image src of the logo
    imgSrc: PropTypes.string.isRequired,
    // the alt for the img
    imgAlt: PropTypes.string.isRequired,
  }),
};

export default Sidebar;
