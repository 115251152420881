import React, { useEffect, useState } from "react";
import {
  Card,
  Row,
  Container,
  CardHeader,
  Col,
  CardBody,
  CardTitle,
  NavItem,
  TabContent,
  NavLink,
  Nav,
  CardText,
  Button,
  TabPane,
  ListGroup,
  ListGroupItem,
} from "reactstrap";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import {
  Table,
  FormGroup,
  FormText,
  Form,
  Input,
  Label,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
} from "reactstrap";
import BasicDetails from "./parentsprofile/BasicDetails";
import StudentDemo from "./parentsprofile/StudentDemo";
import Classes from "./parentsprofile/Classes";
import Payments from "./parentsprofile/Payments";
import parentsModal from "models/parents.modal";
import { helper } from "lib/helper";
import Messages from "views/Messages";
import Avatar from "../../assets/img/Avatar.jpeg";

const ParentsProfile = () => {
  const [activeTab, setActiveTab] = useState("Basicdetails");

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };
  const getTabContentStyle = (tabId) => {
    return {
      display: activeTab === tabId ? "block" : "none",
      transition: "height 0.3s ease-in-out",
      overflow: "hidden",
    };
  };

  const [dataList, setDataList] = useState([]);
  const location = useLocation();
  const params = useParams();

  const getListData = async () => {
    // setIsLoading(true);
    const data = {
      email: params.pid,
    };
    await parentsModal
      .UserData(data)
      .then((result) => {
        // setIsLoading(false);
        if (result) {
          setDataList(result?.data[0]);
        }
      })
      .catch((error) => {
        // helper.sweetalert.toast(error?.response?.data?.message, "warning");
      });
  };

  useEffect(() => {
    getListData();
  }, [location, params?.id]);
  console.log(dataList, "ksjdfjkd");

  const [newSkills, setNewSkills] = useState([]);
  const getListDataNewSkills = async (id) => {
    const data = {
      email: params.pid,
      skillType: "new",
    };
    await parentsModal
      .userSkill(data)
      .then((result) => {
        if (result) {
          setNewSkills(result?.data);
        }
      })
      .catch((error) => {
        // helper.sweetalert.toast(error.response?.data?.message, "warning");
      });
  };

  useEffect(() => {
    getListDataNewSkills();
  }, []);

  const [OldSkills, setOldSkills] = useState([]);
  const getListDataOldSkills = async (id) => {
    const data = {
      email: params.pid,
      skillType: "old",
    };
    await parentsModal
      .userSkill(data)
      .then((result) => {
        if (result) {
          setOldSkills(result?.data);
        }
      })
      .catch((error) => {
        setOldSkills([]);
        // helper.sweetalert.toast(error.response?.data?.message, "warning");
      });
  };

  useEffect(() => {
    getListDataOldSkills();
  }, []);

  console.log(OldSkills, "OldSkills");
  const [isExpanded, setIsExpanded] = useState(false);
  const toggleReadMore = () => setIsExpanded(!isExpanded);

  const MAX_LENGTH = 500;

  return (
    <Container className="px-4 py-7">
      <Row>
        <div className="col"></div>
      </Row>
      <Card className="shadow">
        <CardHeader
          CardHeader
          className="border-0 d-flex flex-column flex-md-row justify-content-between align-items-center"
        >
          <Card className="mb-3 w-100" style={{ borderRadius: ".5rem" }}>
            <Row className="g-0">
              <Col
                md="4"
                className="gradient-custom text-center text-white"
                style={{
                  borderTopLeftRadius: ".5rem",
                  borderBottomLeftRadius: ".5rem",
                  backgroundColor: "#000f26",
                }}
              >
                <img
                  className=" "
                  style={{
                    width: "150px",
                    borderRadius: "50%",
                    marginTop: "80px",
                    height: "150px",
                  }}
                  src={dataList?.image_path || Avatar}
                  alt="avatar"
                  fluid
                />
                <h4 className="mt-3">
                  {" "}
                  {/* {employeeDetails?.prefix} {employeeDetails?.name} */}
                  <br />
                </h4>
              </Col>
              <Col md="8">
                <CardBody className="p-4">
                  <Row className="pt-1">
                    <Col size="6" className="mb-3">
                      <div className="top-card-div">
                        <div
                          style={{
                            lineHeight: "13px",
                            paddingTop: "15px",
                          }}
                        >
                          <p style={{ color: "black", fontWeight: "bold" }}>
                            {" "}
                            <span
                              style={{ color: "lightgray", fontWeight: "bold" }}
                            >
                              Name{" "}
                            </span>
                            {/* - {employeeDetails?.designation} */} -{" "}
                            {dataList?.first_name}{" "}
                          </p>
                        </div>
                      </div>
                    </Col>
                    <Col size="6" className="mb-3">
                      <div className="top-card-div">
                        <div
                          style={{
                            lineHeight: "13px",
                            paddingTop: "15px",
                          }}
                        >
                          <p style={{ color: "black", fontWeight: "bold" }}>
                            <span
                              style={{ color: "lightgray", fontWeight: "bold" }}
                            >
                              Gmail{" "}
                            </span>{" "}
                            {/* - {employeeDetails?.roleName} */} -{" "}
                            {dataList?.email}
                          </p>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <hr className="mt-0 mb-4" />
                  <Row className="pt-1">
                    <Col size="6" className="mb-3">
                      <div className="top-card-div">
                        <p
                          className="card-text"
                          style={{ color: "black", fontWeight: "bold" }}
                        >
                          <span
                            style={{ color: "lightgray", fontWeight: "bold" }}
                          >
                            Grade level -
                          </span>{" "}
                          {dataList?.grade_level}
                          <br />{" "}
                          {/* {employeeDetails?.parent_department_name}{" "} */}
                        </p>
                      </div>
                    </Col>
                    <Col size="6" className="mb-3">
                      <div className="top-card-div">
                        <p
                          className="card-text"
                          style={{ color: "black", fontWeight: "bold" }}
                        >
                          <span
                            style={{ color: "lightgray", fontWeight: "bold" }}
                          >
                            joiningDate -<span></span>
                          </span>{" "}
                          {dataList?.created_at}
                          <br />{" "}
                          {/* {moment(Emphist?.joining_date).format(
                                      "DD/MM/YYYY"
                                    )} */}
                        </p>
                      </div>
                    </Col>
                  </Row>
                  <hr className="mt-0 mb-4" />
                  <Row className="pt-1">
                    <Col size="13" className="mb-3">
                      <div className="top-card-div">
                        <p
                          className="card-text"
                          style={{ color: "black", fontWeight: "bold" }}
                        >
                          <span
                            style={{ color: "lightgray", fontWeight: "bold" }}
                          >
                            School/Institution Name-
                          </span>{" "}
                          {dataList?.school_name}
                          <br />{" "}
                          {/* {employeeDetails?.parent_department_name}{" "} */}
                        </p>
                      </div>
                    </Col>
                  </Row>
                  <hr className="mt-0 mb-4" />
                  <Row className="pt-1">
                    {/* <Col size="12" className="mb-3">
                      <div className="top-card-div">
                        <p className="card-text" style={{ color: "black", fontWeight: "bold" }}>
                          <span
                            style={{ color: "lightgray", fontWeight: "bold" }}
                          >
                            About-
                          </span>{" "}
                          {dataList?.user_bio}
                          <br />{" "}
                          {/* {employeeDetails?.parent_department_name}{" "} */}
                    {/* </p>
                      </div>
                    </Col> */}

                    <Col size="12" className="mb-3">
                      <div className="top-card-div">
                        <p
                          className="card-text"
                          style={{
                            color: "black",
                            fontWeight: "bold",
                            textAlign: "justify",
                          }}
                        >
                          <span
                            style={{ color: "lightgray", fontWeight: "bold" }}
                          >
                            About-
                          </span>{" "}
                          {dataList?.user_bio && (
                            <>
                              {isExpanded
                                ? dataList.user_bio
                                : `${dataList.user_bio.slice(
                                    0,
                                    MAX_LENGTH
                                  )}...`}
                              {dataList.user_bio.length > MAX_LENGTH && (
                                <span
                                  onClick={toggleReadMore}
                                  style={{
                                    color: "blue",
                                    cursor: "pointer",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {isExpanded ? " Read Less" : " Read More"}
                                </span>
                              )}
                            </>
                          )}
                        </p>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Col>
            </Row>
          </Card>
        </CardHeader>
        <CardBody>
          <div className="container ">
            <div className="row">
              <div className="col-md-12 px-2.5">
                <div
                  className="nav nav-pills flex-column flex-md-row"
                  id="v-pills-tab"
                  role="tablist"
                  style={{ width: "100%" }}
                >
                  <button
                    className={`col-md-3 nav-link ${
                      activeTab === "Basicdetails" ? "active" : ""
                    }`}
                    id="v-pills-home-tab"
                    onClick={() => handleTabClick("Basicdetails")}
                    type="button"
                    role="tab"
                    aria-controls="Basicdetails"
                    aria-selected={activeTab === "Basicdetails"}
                    style={{
                      textAlign: "left",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      height: "70px",
                      borderRadius: "0",
                      borderTop: "none",
                      border: "1px solid gray",
                      fontSize: "16px",
                    }}
                  >
                    Post
                  </button>

                  <button
                    className={`col-md-3 nav-link ${
                      activeTab === "Message" ? "active" : ""
                    }`}
                    id="v-pills-profile-tab"
                    onClick={() => handleTabClick("Message")}
                    type="button"
                    role="tab"
                    aria-controls="Message"
                    aria-selected={activeTab === "Message"}
                    style={{
                      textAlign: "left",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      height: "70px",
                      borderRadius: "0",
                      borderTop: "none",
                      border: "1px solid gray",
                      fontSize: "16px",
                    }}
                  >
                    Message
                  </button>

                  <button
                    className={`col-md-3 nav-link ${
                      activeTab === "Skillhaving" ? "active" : ""
                    }`}
                    id="v-pills-profile-tab"
                    onClick={() => handleTabClick("Skillhaving")}
                    type="button"
                    role="tab"
                    aria-controls="Skillhaving"
                    aria-selected={activeTab === "Skillhaving"}
                    style={{
                      textAlign: "left",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      height: "70px",
                      borderRadius: "0",
                      borderTop: "none",
                      border: "1px solid gray",
                      fontSize: "16px",
                    }}
                  >
                    Skill Having
                  </button>

                  <button
                    className={`col-md-3 nav-link ${
                      activeTab === "SkillBuild" ? "active" : ""
                    }`}
                    id="v-pills-profile-tab"
                    onClick={() => handleTabClick("SkillBuild")}
                    type="button"
                    role="tab"
                    aria-controls="SkillBuild"
                    aria-selected={activeTab === "SkillBuild"}
                    style={{
                      textAlign: "left",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      height: "70px",
                      borderRadius: "0",
                      borderTop: "none",
                      border: "1px solid gray",
                      fontSize: "16px",
                    }}
                  >
                    Skill Want To Build
                  </button>
                </div>
              </div>
            </div>

            {/* <div > */}
            <div
              className="col-12  px-0"
              id="v-pills-tabContent"
              style={{
                border: "1px solid gray",
                padding: "20px",
                marginTop: "5px",
              }}
            >
              <div style={getTabContentStyle("Basicdetails")}>
                <CardBody>
                  <BasicDetails data={dataList} />
                </CardBody>
              </div>
              <div style={getTabContentStyle("Message")}>
                <Messages />
              </div>

              <div style={getTabContentStyle("Skillhaving")}>
                <CardBody>
                  <Table>
                    <thead>
                      <tr style={{ background: "#F6F9FC" }}>
                        <th>#</th>
                        <th>Skill Name</th>
                        <th>Sub-Skill Name</th>
                      </tr>
                    </thead>

                    <tbody>
                      {OldSkills?.map((item, index) => {
                        return (
                          <tr key={item._id}>
                            <td>{index + 1}</td>
                            <td>{item?.category_name}</td>
                            <td>{item?.skill_name}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </CardBody>
              </div>
              <div style={getTabContentStyle("SkillBuild")}>
                <CardBody>
                  <Table>
                    <thead>
                      <tr style={{ background: "#F6F9FC" }}>
                        <th>#</th>
                        <th>Skill Name</th>
                        <th>Sub-Skill Name</th>
                      </tr>
                    </thead>

                    <tbody>
                      {newSkills?.map((item, index) => {
                        return (
                          <tr key={item._id}>
                            <td>{index + 1}</td>
                            <td>{item?.category_name}</td>
                            <td>{item?.skill_name}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </CardBody>
              </div>
            </div>
            {/* </div> */}
          </div>
        </CardBody>
      </Card>
    </Container>
  );
};

export default ParentsProfile;
