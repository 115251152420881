import { Card, CardBody, CardTitle, Container, Row, Col } from "reactstrap";
import DashboardKpi from "./Dashboard/DashboardKpi";
import kpiModel from "models/kpi.model";
import { helper } from "lib/helper";
import { useEffect, useState } from "react";
import { Doughnut } from "react-chartjs-2";

const Header = () => {
  const [totalUser,setTotalUser] = useState(0)
  const[totalSkills,setTotalSkills] = useState(0)
  const[totalSubSkills,setTotalSubSkills] = useState(0)
  const[totalPost,setTotalPost] = useState(0)

  const storedUserInfo = JSON.parse(sessionStorage.getItem('userinfo'));
  const getDashboardKpi = async () => {
    const data = { email: storedUserInfo?.email, role: "admin" };
    await kpiModel
      .dashboardKpi(data)
      .then((result) => {
        console.log(result.data,"dashobardkiapi")
        setTotalUser(result?.data?.total_user);
        setTotalSkills(result?.data?.total_category);
        setTotalSubSkills(result?.data?.total_skill);
        setTotalPost(result?.data?.total_post);
      })
      .catch((error) => {
        // console.log(error);
      });
  };
  const totalRides = helper.generateRandomNumber();

  const config = {
    type: "doughnut",
    data: {
      labels: ["Red", "Blue"],

      datasets: [
        {
          label: "# of Votes",
          data: [12, 19],
          backgroundColor: [
            "rgba(255, 99, 132, 0.6)",
            "rgba(54, 162, 235, 0.6)",
            "rgba(255, 206, 86, 0.6)",
          ],
          borderColor: [
            "rgba(255, 99, 132, 1)",
            "rgba(54, 162, 235, 1)",
            "rgba(255, 206, 86, 1)",
          ],

          borderWidth: 1,
        },
      ],
    },
  };

  useEffect(() => {
    getDashboardKpi();
  }, []);

  return (
    <>
      <div className="header  pb-8 pt-5 pt-md-8">
        <Container fluid>
          <div className="header-body">
            {/* Card stats */}
            <Row>
              <DashboardKpi
                kpiTitle="TOTAL USERS"
                kpiValue={totalUser}
                kpiIcon="fa-solid fa-user"
                iconBakgroundColor="bg-success"
                navigateUrl="/admin/user"
              />
              <DashboardKpi
                kpiTitle="TOTAL SKILLS"
                kpiValue={totalSkills}
                kpiIcon="fa-solid fa-user-tie"
                iconBakgroundColor="bg-primary"
                navigateUrl="/admin/skillcategorys"
              />

              <DashboardKpi
                kpiTitle="TOTAL SUBSKILLS"
                kpiValue={totalSubSkills}
                kpiIcon="fa-solid fa-child-dress"
                iconBakgroundColor="bg-warning"
                navigateUrl="/admin/skillSubcategorys"
              />

              {/* <DashboardKpi
                kpiTitle=" Inactive Drivers"
                kpiValue={driverCount}
                kpiIcon="fas fa-user-astronaut"
                iconBakgroundColor="bg-warning"
                navigateUrl="/admin/drivers"
              /> */}

              <DashboardKpi
                kpiTitle="TOTAL POST"
                kpiValue={totalPost}
                kpiIcon="fa-solid fa-chess-board"
                iconBakgroundColor="bg-info"
                navigateUrl="/admin/post"
              />

              {/* <DashboardKpi
                kpiTitle="TOTAL SUBJECTS"
                kpiValue={supportCount}
                kpiIcon="fa-solid fa-book-open-reader"
                iconBakgroundColor="bg-yellow"
                navigateUrl="/admin/messages"
              /> */}
            </Row>
          </div>

          {/* <div>
            <Row>
              <Col>
                <Card className=" d-flex align-items-center border-none">
                  <Doughnut data={config.data} />

                  <h4 className="pt-2">Active Riders</h4>
                  <h5>Total: 2000</h5>
                  <h5>Active: 1200</h5>
                </Card>
              </Col>
              <Col>
                <Card className="mx-4 d-flex align-items-center ">
                  <Doughnut data={config.data} />
                  <h4 className="pt-2">Active Users</h4>
                  <h5>Total: 6000</h5>
                  <h5>Active: 1700</h5>
                </Card>
              </Col>
            </Row>
          </div> */}

          {/* <div
            className="pt-3  bg-white my-2 "
            style={{ borderRadius: "8px" }}
          >

            <h2 className="px-4">Active </h2>

            <div className="d-flex flex-row p-4">
              <Row>
                <Col md={6}>
                  <Card className=" d-flex align-items-center border-none">
                    <Doughnut data={config.data} />

                    <h4 className="pt-2">Active Riders</h4>
                    <h5>Total: 2000</h5>
                    <h5>Active: 1200</h5>
                  </Card>
                </Col>

                <Col md={6}>
                  <Card className="mx-4 d-flex align-items-center ">
                    <Doughnut data={config.data} />
                    <h4 className="pt-2">Active Users</h4>
                    <h5>Total: 6000</h5>
                    <h5>Active: 1700</h5>
                  </Card>
                </Col>
              </Row>
            </div>
          </div> */}
          {/* <div className="d-flex  p-4 bg-white" >
<Card className=" align-items-center border-none">

<Doughnut data={config.data} />

<h4 className="pt-2">Active Riders</h4>

<h5>Total: 2000</h5>
<h5>Active: 1200</h5>

</Card>
<Card className=" align-items-center border-none">

<Doughnut data={config.data} />

<h4 className="pt-2">Active Riders</h4>

<h5>Total: 2000</h5>
<h5>Active: 1200</h5>

</Card>
</div> */}

          {/* <div className="pt-3 my-2  bg-white " style={{ borderRadius: "8px" }}>
            <h2 className="px-4">Active</h2>
            <div className="d-flex  flex-row p-4">
              {/* <Container className="">
          

                <Row className="">
                  <Col lg={3} className="mb-4 mb-lg-0 ">
                    {" "}
                    <Card className="d-flex align-items-center border-none">
                      <Doughnut data={config.data} />

                      <h4 className="pt-2">Active Riders</h4>

                      <h5>Total: 2000</h5>
                      <h5>Active: 1200</h5>
                    </Card>
                  </Col>

                  <Col lg={3} className="mb-4 mb-lg-0 ">
                    {" "}
                    <h2 className="px-4">{""}</h2>
                    <Card className="mt-2 d-flex align-items-center">
                      <Doughnut data={config.data} />

                      <h4 className="pt-2">Active Users</h4>

                      <h5>Total: 6000</h5>
                      <h5>Active: 1700</h5>
                    </Card>
                  </Col>
                </Row>
              </Container> */}

          {/* </div>
          </div> */}
        </Container>
      </div>
    </>
  );
};

export default Header;
