import React, { useState } from "react";
import { Table, Button, Card } from "reactstrap";

import {
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";

const AddModal = ({ toggle, modal, title, Name }) => {
  const [checkedAll, setCheckedAll] = useState(false);
  
  const [checkboxes, setCheckboxes] = useState(Array(5).fill(false)); // Assuming 5 rows for demonstration
  const [viewChecked, setViewChecked] = useState(false);

  const [checkAdd, setcheckAdd] = useState(false);
  const [deleteChecked, setDeleteChecked] = useState(false);

  const handleCheckboxChange = (index) => {
    const newCheckboxes = [...checkboxes];
    newCheckboxes[index] = !newCheckboxes[index];
    setCheckboxes(newCheckboxes);
  };

  const handleAddButtonClick = () => {
    setCheckboxes(Array(5).fill(!checkedAll));

    setCheckedAll(!checkedAll);
    setcheckAdd(!checkAdd)
    setViewChecked(!checkedAll);
    setDeleteChecked(!checkedAll);
    // setCheckedAll(!checkedAll);
  };

  const showData = () => {
    switch (Name) {
      case "Add1":
        return (
          <>
            <Form>
              <Row>
                <Col md={6}>
                  <FormGroup>
                    <Label for="exampleEmail">Banner Image</Label>
                    <Input
                      style={{
                        border: "1px solid lightgrey",
                        padding: "6px 10px",
                        borderRadius: "6px",
                      }}
                      id="name"
                      name="name"
                      type="file"
                    />
                  </FormGroup>
                </Col>

                <Col md={6}>
                  <FormGroup>
                    <Label for="exampleEmail">Name</Label>
                    <Input
                      id="name"
                      name="name"
                      type="name"
                      placeholder="Enter Banner Name"
                    />
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                <Col md={6}>
                  <FormGroup>
                    <Label for="exampleEmail">Display Order</Label>
                    <Input id="contact" name="contact" type="number" />
                  </FormGroup>
                </Col>

                <Col md={6}>
                  <FormGroup>
                    <Label for="exampleEmail">Time Period</Label>
                    <Input id="status" name="status" type="status" />
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                <Col md={6}>
                  <FormGroup>
                    <Label for="exampleEmail">Added Date</Label>
                    <Input id="contact" name="contact" type="number" />
                  </FormGroup>
                </Col>

                <Col md={6}>
                  <FormGroup>
                    <Label for="exampleEmail">Status</Label>
                    <Input id="status" name="status" type="select">
                      <option value="">Activate</option>
                      <option value="option1">Deactivate</option>
                      <option value="option2">Pending</option>
                    </Input>
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                <Col md={6}>
                  <FormGroup>
                    <Label for="exampleEmail">Banner Type</Label>
                    <Input id="contact" name="contact" type="select">
                      <option value="">Driver</option>
                      <option value="option1">User</option>
                      <option value="option2">Both</option>
                    </Input>
                  </FormGroup>
                </Col>
              </Row>
            </Form>
          </>
        );
      case "Add2":
        return (
          <>
            <Form>
              <Row>
                <Col md={4}>
                  <FormGroup>
                    <Label for="exampleEmail">FAQ Type</Label>
                    <Input id="name" name="name" type="select">
                      <option value="">Driver</option>
                      <option value="option1">User</option>
                      <option value="option2">Both</option>
                    </Input>
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                <Col>
                  <FormGroup>
                    <Label for="exampleEmail">Question</Label>
                    <Input id="name" name="name" type="text" />
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                <Col>
                  <FormGroup>
                    <Label for="exampleEmail">Answer</Label>
                    <Input id="contact" name="contact" type="textarea" />
                  </FormGroup>
                </Col>
              </Row>
            </Form>
          </>
        );

      case "Add3":
        return (
          <>
            <Form>
              <Row>
                <Col md={6}>
                  <FormGroup>
                    <Label for="exampleEmail">Coupen Code</Label>
                    <Input id="name" name="name" type="text" />
                  </FormGroup>
                </Col>

                <Col md={6}>
                  <FormGroup>
                    <Label for="exampleEmail">Discount % </Label>
                    <Input
                      id="name"
                      name="name"
                      type="name"
                      placeholder="Enter Discount"
                    />
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                <Col md={6}>
                  <FormGroup>
                    <Label for="exampleEmail">Validity</Label>
                    <Input id="contact" name="contact" type="number" />
                  </FormGroup>
                </Col>

                <Col md={6}>
                  <FormGroup>
                    <Label for="exampleEmail">Coupen Type</Label>
                    <Input id="status" name="status" type="status" />
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                <Col md={6}>
                  <FormGroup>
                    <Label for="exampleEmail">Activation Date</Label>
                    <Input id="contact" name="text" type="text" />
                  </FormGroup>
                </Col>

                <Col md={6}>
                  <FormGroup>
                    <Label for="exampleEmail">Expiry Date</Label>
                    <Input id="contact" name="text" type="text" />
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                <Col md={6}>
                  <FormGroup>
                    <Label for="exampleEmail">Usase Limit</Label>
                    <Input id="contact" name="contact" type="text"></Input>
                  </FormGroup>
                </Col>

                <Col md={6}>
                  <FormGroup>
                    <Label for="exampleEmail">Status</Label>
                    <Input id="contact" name="contact" type="select">
                      <option value="">Activate</option>
                      <option value="option1">Deactivate</option>
                      <option value="option2">Pending</option>
                    </Input>
                  </FormGroup>
                </Col>
              </Row>
            </Form>
          </>
        );

      case "Add6":
        return (
          <>
            <Form>
              <Row>
                <Col md={6}>
                  <FormGroup>
                    <Label for="exampleEmail">State</Label>
                    <Input
                      id="name"
                      name="name"
                      type="text"
                      placeholder="Enter  State Name"
                    />
                  </FormGroup>
                </Col>

                <Col md={6}>
                  <FormGroup>
                    <Label for="exampleEmail">City </Label>
                    <Input
                      id="name"
                      name="name"
                      type="name"
                      placeholder="Enter City Name"
                    />
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                <Col md={6}>
                  <FormGroup>
                    <Label for="exampleEmail">Fare Name</Label>
                    <Input
                      id="contact"
                      name="contact"
                      type="text"
                      placeholder=" Enter Fare Name"
                    />
                  </FormGroup>
                </Col>

                <Col md={6}>
                  <FormGroup>
                    <Label for="exampleEmail">Base KM</Label>
                    <Input
                      id="status"
                      name="status"
                      type="status"
                      placeholder=" Enter Base Kilometer"
                    />
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                <Col md={6}>
                  <FormGroup>
                    <Label for="exampleEmail">Base KM Fare</Label>
                    <Input
                      id="contact"
                      name="text"
                      type="text"
                      placeholder=" Enter Base KM Fare"
                    />
                  </FormGroup>
                </Col>

                <Col md={6}>
                  <FormGroup>
                    <Label for="exampleEmail">Vehicle Modal</Label>
                    <Input
                      id="contact"
                      name="text"
                      type="select"
                      placeholder="Select Vehicle"
                    >
                      <option value="">Sedan</option>
                      <option value="option1">HathBack</option>
                      <option value="option2">SUV</option>
                    </Input>
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                <Col md={6}>
                  <FormGroup>
                    <Label for="exampleEmail">Per KM FARE </Label>
                    <Input
                      id="contact"
                      name="contact"
                      type="text"
                      placeholder=" Enter Fare Per KiloMeter"
                    ></Input>
                  </FormGroup>
                </Col>

                {/* <Col md={6}>
                  <FormGroup>
                    <Label for="exampleEmail">Status</Label>
                    <Input id="contact" name="contact" type="select">
                      <option value="">Activate</option>
                      <option value="option1">Deactivate</option>
                      <option value="option2">Pending</option>
                    </Input>
                  </FormGroup>
                </Col> */}
              </Row>
            </Form>
          </>
        );

      case "Add8":
        return (
          <>
            <Form>
              {/* <Card> */}
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">S.No</th>
                    <th scope="col">Name</th>

                    <th scope="col">
                      <Button
                        variant="primary"
                        size="sm"
                        checked={checkAdd}
                        onClick={handleAddButtonClick}
                      >
                        {checkedAll ? "+" : "-"}
                      </Button>
                    </th>

                    <th scope="col">
                      <input
                        type="checkbox"
                        checked={viewChecked}
                        onChange={() => setViewChecked(!viewChecked)}
                      />{" "}
                      View
                    </th>

                    <th scope="col">
                      <input
                        type="checkbox"
                        checked={deleteChecked}
                        onChange={() => setDeleteChecked(!deleteChecked)}
                      />{" "}
                      Delete
                    </th>

                  
                  </tr>
                </thead>

                <tbody>
                  {checkboxes.map((checked, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>

                      <td>Name {index + 1}</td>

                      <td>
                        <input
                          type="checkbox"
                          checked={checked || viewChecked}
                          onChange={() => handleCheckboxChange(index)}
                        />
                      </td>

                      <td>
                        <input
                          type="checkbox"
                          checked={checked}
                          onChange={() => handleCheckboxChange(index)}
                        />
                      </td>

                      <td>
                        <input
                          type="checkbox"
                          checked={checked || deleteChecked}
                          onChange={() => handleCheckboxChange(index)}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>

              {/* </Card> */}
            </Form>
          </>
        );
        case "Add9":
          return (
            <>
              <Form>
                <Row>
                  <Col md={6}>
                    <FormGroup>
                      <Label for="exampleEmail">User Name</Label>
                      <Input
                        id="name"
                        name="name"
                        type="text"
                        placeholder="User Name"
                      />
                    </FormGroup>
                  </Col>
  
                  <Col md={6}>
                    <FormGroup>
                      <Label for="exampleEmail">Mobile Number </Label>
                      <Input
                        id="name"
                        name="name"
                        type="name"
                        placeholder="Mobile Number"
                      />
                    </FormGroup>
                  </Col>
                </Row>
  
                <Row>
                  <Col md={6}>
                    <FormGroup>
                      <Label for="exampleEmail">Email</Label>
                      <Input
                        id="contact"
                        name="contact"
                        type="email"
                        placeholder=" Enter Email"
                      />
                    </FormGroup>
                  </Col>
  
                  <Col md={6}>
                    <FormGroup>
                      <Label for="exampleEmail">City</Label>
                      <Input
                        id="status"
                        name="status"
                        type="status"
                        placeholder=" Enter City"
                      />
                    </FormGroup>
                  </Col>
                </Row>
  
                <Row>
                  <Col md={6}>
                    <FormGroup>
                      <Label for="exampleEmail">Gender</Label>
                      <Input
                        id="contact"
                        name="text"
                        type="select"
                        // placeholder=" Enter Base KM Fare"
                      >
                         <option value="">Male</option>
                      <option value="option1">Female</option>
                      <option value="option2">Other</option>

                       
                      </Input>
                    </FormGroup>
                  </Col>
  
                  {/* <Col md={6}>
                    <FormGroup>
                      <Label for="exampleEmail">Vehicle Modal</Label>
                      <Input
                        id="contact"
                        name="text"
                        type="select"
                        placeholder="Select Vehicle"
                      >
                        <option value="">Sedan</option>
                        <option value="option1">HathBack</option>
                        <option value="option2">SUV</option>
                      </Input>
                    </FormGroup>
                  </Col> */}
                </Row>
  
                <Row>
                  {/* <Col md={6}>
                    <FormGroup>
                      <Label for="exampleEmail">Per KM FARE </Label>
                      <Input
                        id="contact"
                        name="contact"
                        type="text"
                        placeholder=" Enter Fare Per KiloMeter"
                      ></Input>
                    </FormGroup>
                  </Col> */}
  
                  {/* <Col md={6}>
                    <FormGroup>
                      <Label for="exampleEmail">Status</Label>
                      <Input id="contact" name="contact" type="select">
                        <option value="">Activate</option>
                        <option value="option1">Deactivate</option>
                        <option value="option2">Pending</option>
                      </Input>
                    </FormGroup>
                  </Col> */}
                </Row>
              </Form>
            </>
          );

          case "tutors":
            return (
              <>
                <Form>
                  <Row>
                    <Col md={6}>
                      <FormGroup>
                        <Label for="exampleEmail">User Name</Label>
                        <Input
                          id="name"
                          name="name"
                          type="text"
                          placeholder="User Name"
                        />
                      </FormGroup>
                    </Col>
    
                    <Col md={6}>
                      <FormGroup>
                        <Label for="exampleEmail">Mobile Number </Label>
                        <Input
                          id="name"
                          name="name"
                          type="name"
                          placeholder="Mobile Number"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
    
                  <Row>
                    <Col md={6}>
                      <FormGroup>
                        <Label for="exampleEmail">Email</Label>
                        <Input
                          id="contact"
                          name="contact"
                          type="email"
                          placeholder=" Enter Email"
                        />
                      </FormGroup>
                    </Col>
    
                    <Col md={6}>
                      <FormGroup>
                        <Label for="exampleEmail">City</Label>
                        <Input
                          id="status"
                          name="status"
                          type="status"
                          placeholder=" Enter City"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
    
                  <Row>
                    <Col md={6}>
                      <FormGroup>
                        <Label for="exampleEmail">Gender</Label>
                        <Input
                          id="contact"
                          name="text"
                          type="select"
                          // placeholder=" Enter Base KM Fare"
                        >
                           <option value="">Male</option>
                        <option value="option1">Female</option>
                        <option value="option2">Other</option>
  
                         
                        </Input>
                      </FormGroup>
                    </Col>
    
                    {/* <Col md={6}>
                      <FormGroup>
                        <Label for="exampleEmail">Vehicle Modal</Label>
                        <Input
                          id="contact"
                          name="text"
                          type="select"
                          placeholder="Select Vehicle"
                        >
                          <option value="">Sedan</option>
                          <option value="option1">HathBack</option>
                          <option value="option2">SUV</option>
                        </Input>
                      </FormGroup>
                    </Col> */}
                  </Row>
    
                  <Row>
                    {/* <Col md={6}>
                      <FormGroup>
                        <Label for="exampleEmail">Per KM FARE </Label>
                        <Input
                          id="contact"
                          name="contact"
                          type="text"
                          placeholder=" Enter Fare Per KiloMeter"
                        ></Input>
                      </FormGroup>
                    </Col> */}
    
                    {/* <Col md={6}>
                      <FormGroup>
                        <Label for="exampleEmail">Status</Label>
                        <Input id="contact" name="contact" type="select">
                          <option value="">Activate</option>
                          <option value="option1">Deactivate</option>
                          <option value="option2">Pending</option>
                        </Input>
                      </FormGroup>
                    </Col> */}
                  </Row>
                </Form>
              </>
            );

      default:
        return null;
    }
  };
  return (
    <>
      <Modal size="lg" isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>{title}</ModalHeader>
        <ModalBody>{showData()}</ModalBody>
        <ModalFooter
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
          <Button color="primary" onClick={toggle}>
            Save
          </Button>{" "}
        </ModalFooter>
      </Modal>
    </>
  );
};

export default AddModal;
