import axiosInstance from "../lib/axiosInstance";

const SubAdminModal = class {
  constructor() {
    this.baseUrl = "/subadmin/";
  }

  async list(data = []) {
    return (
      await axiosInstance.get(`${this.baseUrl}`)
    ).data;
  }

  async listbyUserid(data = []) {
    return (
      await axiosInstance.get(`${this.baseUrl}`,{params:data})
    );
  }
  // async removeUser(id = {}) {
  //   return await axiosInstance.delete(`${this.baseUrl}?id=${id}`);
  // }
  async removeUser(data = {}) {
    return await axiosInstance.delete(`${this.baseUrl}?id=${data}`);
  }
  

  async create(data = []) {
    return await axiosInstance.post(this.baseUrl, data);
  }

  async editUser(data = []) {
    return await axiosInstance.put(this.baseUrl, data);
  }
  
  async InterviewSchedule(data = []) {
    console.log(data,"InterviewSchedule")
    return await axiosInstance.post('/shinterview/', data);
  }
 
};

export default new SubAdminModal();
