import Index from "views/Index.js";
import Profile from "views/Profile.js";
import Login from "views/Login.js";
// import Clientms from "views/cms";


import Messages from "views/Messages";
import Students from "views/Student.js";
import AddStudent from "views/Student.js/AddStudent";
import ParentsProfile from "views/Student.js/ParentsProfile";
import Skillcategorys from "views/skillcategorys/Index";
import SkillSubcategorys from "views/skillSubcategorys/Index";
import Post from "views/post";



var routes = [
  {
    path: "/index",
    name: "Dashboard",
    icon: "fa fa-home text-primary",
    component: <Index />,
    layout: "/admin",
    IsVisible: true,
  },
 

  {
    path: "/user",
    path: "/user",
    name: "User",
    icon: "fa fa-chalkboard-user text-primary",
    component: <Students/>,
    layout: "/admin",
    IsVisible: true,
  },
  {

    path: "/skillcategorys",
    name: "Skills Category",
    icon: "fa fa-chalkboard-user text-primary",
    component: <Skillcategorys/>,
    layout: "/admin",
    IsVisible: true,
  },

  {

    path: "/skillSubcategorys",
    name: "Sub-Skill ",
    icon: "fa fa-chalkboard-user text-primary",
    component: <SkillSubcategorys/>,
    layout: "/admin",
    IsVisible: true,
  },
  {

    path: "/post",
    name: "Post",
    icon: "fa fa-chalkboard-user text-primary",
    component: <Post/>,
    layout: "/admin",
    IsVisible: true,
  },

  
  {
    path: "/student/addstudent/",
    component: <AddStudent />,
    layout: "/admin",
    IsVisible: false,
  },
  {
    path: "/student/parentsProfile/:id/:pid",
    component: <ParentsProfile />,
    layout: "/admin",
    IsVisible: false,
  },
  {
    path: "/login",
    name: "Login",
    icon: "ni ni-key-25 text-info",
    component: <Login />,
    layout: "/auth",
    IsVisible: false,
  },


];
export default routes;
