import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
} from "reactstrap";
import { helper } from "lib/helper";
import AuthModel from "models/auth.model";
import { useState } from "react";

const Login = () => {
  const [formData, setFormData] = useState({ userName: "", password: "" });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = {
      email: e.target.email.value,
      password: e.target.password.value,
    };
    console.log(data, "data");
    if (e.target.email.value != "" && e.target.password.value != "") {
      await AuthModel.login(data)
        .then((res) => {
          if (res) {
            console.log(res,"message")

            if (res.msg == "Successfully Login") {
              console.log(res, "logindata");
              sessionStorage.setItem("access_token", res.token);
              sessionStorage.setItem("type", res.role);
              sessionStorage.setItem("userinfo", JSON.stringify(res));
              window.location.assign("/admin/index");
              helper.sweetalert.toast(res.msg, "success");
            }
        
             else {
              helper.sweetalert.toast(res.msg, "error");
            }
           
          }
        })
        .catch((error) => {
          console.log(error, "error");
          helper.sweetalert.toast(error.res?.data?.error, "warning");
        });
    }
    // console.log(e.target.email.value);
  };

  return (
    <>
      <Col lg="5" md="7">
        <Card className="bg-secondary shadow border-0">
          <CardBody className="px-lg-5 py-lg-5">
            <div className="text-center text-muted mb-4">
              <small>Sign in with credentials</small>
            </div>
            <Form role="form" onSubmit={handleSubmit}>
              <FormGroup className="mb-3">
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-email-83" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Email"
                    type="text"
                    name="email"
                    autoComplete="new-email"
                    required
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Password"
                    type="password"
                    name="password"
                    autoComplete="new-password"
                    required
                  />
                </InputGroup>
              </FormGroup>

              <div className="text-center">
                <Button className="my-4" color="primary" type="submit">
                  Sign in
                </Button>
              </div>
            </Form>
          </CardBody>
        </Card>
      </Col>
    </>
  );
};

export default Login;
