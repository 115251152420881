import axiosInstance from "../lib/axiosInstance";

const Tutorsmodel = class {
  constructor() {
    this.baseUrl = "/tutor/";
  }

  async Tutorslist(query=[])
  {
      return (await axiosInstance.get(`${this.baseUrl}?${new URLSearchParams(query)}`)).data;
  }
  async tutorslistallData(query=[])
  {
      return (await axiosInstance.get(`${this.baseUrl}?page=1&size=100`)).data;
  }

  async listbyTutorsid(data = []) {
    return (
        await axiosInstance.get(`${this.baseUrl}?id=${data}`)
      // await axiosInstance.get(`${this.baseUrl}`,{params:data})
    );
  }

  async TeachingPreferencelist(data = []) {
    return (
        await axiosInstance.get(`/teachps/?id=${data.id}`)
      // await axiosInstance.get(`${this.baseUrl}`,{params:data})
    );
  }
  
  async TutorsClasslist(id = []) {
    return (
        await axiosInstance.get(`/c-class/?tutor=${id}`)
      // await axiosInstance.get(`${this.baseUrl}`,{params:data})
    );
  }
  

  async TeachingPreferenceInterviewlist(id,parmeter = []) {
    return (
        await axiosInstance.get(`/shinterview/?tutor=${id}`)
      // await axiosInstance.get(`${this.baseUrl}`,{params:data})
    );
  }
  // async Tutorslist(query=[])
  // {
  //     return (await axiosInstance.get(`${this.baseUrl}?${new URLSearchParams(query)}`)).data;
  // }
  
  async TeachingshInterviewQualifiedSubject(id = []) {
    return (
        await axiosInstance.get(`/shinterview/?id=${id}`)
      // await axiosInstance.get(`${this.baseUrl}`,{params:data})
    );
  }
  
 

  async TeachingLocationlist(data = []) {
    return (
        await axiosInstance.get(`/tl/?id=${data.id}`)
      // await axiosInstance.get(`${this.baseUrl}`,{params:data})
    );
  }
  async TeachingQualificationlist(data = []) {
    return (
        await axiosInstance.get(`/qual/?tid=${data.id}`)
      // await axiosInstance.get(`${this.baseUrl}`,{params:data})
    );
  }
  async PersonalInformationlist(data = []) {
    return (
        await axiosInstance.get(`/pi/?id=${data.id}`)
      // await axiosInstance.get(`${this.baseUrl}`,{params:data})
    );
  }
  async TeachingExperienceslist(data = []) {
    return (
        await axiosInstance.get(`/exp/?tid=${data.id}`)
      // await axiosInstance.get(`${this.baseUrl}`,{params:data})
    );
  }

  async interviewStatusChnage(data) {
    try {
        const headers = {
            'Content-Type': 'application/json'
        };
        const jsonData = JSON.stringify(data);
        const response = await axiosInstance.post('/cinterview/', jsonData, {headers});
        return response.data;
    } catch (error) {
        console.error("Error creating class:", error);
        throw error; // Rethrow the error for handling at the calling site
    }
}


async createTutorsProfile(data) {
  console.log(data, "classmodal");
  try {
      const headers = {
          'Content-Type': 'application/json'
      };
      const jsonData = JSON.stringify(data);
      const response = await axiosInstance.post('/tutor_profile/', jsonData, {headers});
      return response.data;
  } catch (error) {
      console.error("Error creating class:", error);
      throw error; // Rethrow the error for handling at the calling site
  }
}

async editTutorsProfile(data) {
  console.log(data, "classmodal");
  try {
      const headers = {
          'Content-Type': 'application/json'
      };
      const jsonData = JSON.stringify(data);
      const response = await axiosInstance.put('/tutor_profile/', jsonData, {headers});
      return response.data;
  } catch (error) {
      console.error("Error creating class:", error);
      throw error; // Rethrow the error for handling at the calling site
  }
}


async TutorsProfileGet(id = []) {
  return (
      await axiosInstance.get(`/tutor_profile/?id=${id}`)
    // await axiosInstance.get(`${this.baseUrl}`,{params:data})
  );
}

  async removeTutors(data = {}) {
    return await axiosInstance.delete(this.baseUrl, { data: data });
  }

  async createTutors(data = []) {
    return await axiosInstance.post(this.baseUrl, data);
  }

  async editTutors(data = []) {
    return await axiosInstance.put(this.baseUrl, data);
  }
 
};

export default new Tutorsmodel();
