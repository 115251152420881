import axiosInstance from "../lib/axiosInstance";

const KpiModel=class {

    constructor(){
        this.baseUrl="/dashboard";
    }

    // async dashboardKpi(query=[])
    // {
    //     return (await axiosInstance.get(`$/get/admin/widegtCount?${new URLSearchParams(query)}`)).data;
    // }

    async dashboardKpi(data) {
        try {
            const headers = {
                'Content-Type': 'application/json'
            };
            const jsonData = JSON.stringify(data);
            const response = await axiosInstance.post(`/get/admin/widegtCount`, jsonData, {headers});
            return response.data;
        } catch (error) {
            console.error("Error creating class:", error);
            throw error; // Rethrow the error for handling at the calling site
        }
      }
    
}

export default new KpiModel();