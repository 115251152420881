import axiosInstance from "../lib/axiosInstance";



const authModel = class {

    // async login(data = []) {
    //     // console.log("checkkkkk",data);
    //     return await axiosInstance.post("https://sklinker.ablive.in/login/user", data);
    // }

    async login(data) {
        try {
            const headers = {
                'Content-Type': 'application/json'
            };
            const jsonData = JSON.stringify(data);
            const response = await axiosInstance.post("/login/user", jsonData, {headers});
            return response.data;
        } catch (error) {
            console.error("Error creating class:", error);
            throw error; // Rethrow the error for handling at the calling site
        }
    }


}

export default new authModel();
