import React from "react";

const AllChat = ({
  messageItem,
  messageTime,
  fromEmail = "",
  toEmail = "",
}) => {
  const loggedEmail = sessionStorage.getItem("userinfo");
  const userInfoObject = JSON.parse(loggedEmail);
  const loggedEmailget = userInfoObject.email;
  console.log(loggedEmailget, "userinfo");
  return (
    <>
      <div
        className={`d-flex flex-row ${
          loggedEmailget == fromEmail
            ? "justify-content-end  pl-3"
            : "justify-content-start"
        }  mb-4`}
      >
        <div
          className="p-3 ms-3"
          style={{
            borderRadius: "15px",
         
            backgroundColor: loggedEmailget == fromEmail ? 'rgba(50, 12, 237,.2)':"rgba(57, 192, 237,.2)"
          }}
        >
          <p className="small mb-0" >{messageItem}</p>
          <span style={{ fontSize: "10px", color: "#5f6164" }}>
            {messageTime}
          </span>
        </div>
      </div>
    </>
  );
};

export default AllChat;
