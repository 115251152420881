import axiosInstance from "../lib/axiosInstance";

const adminProfilemodal=class {

    constructor(){
        this.baseUrl="/profile/";
    }

    async profile( data = {}) {
        //axiosInstance.defaults.headers.common['Authorization'] = "Bearer "+localStorage.getItem("access_token");
        return (await axiosInstance.get(`${this.baseUrl}`)).data;
    }

    async updateProfiles(data=[])
    {
        return await axiosInstance.post(this.baseUrl,data);
    }

    async updatePass(data=[])
    {
        return await axiosInstance.put(`${this.baseUrl}`,data);
    }
   



}

export default new adminProfilemodal();