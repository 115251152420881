import axiosInstance from "../lib/axiosInstance";

const PostModel=class {

    constructor(){
        this.baseUrl="/show/";
    }
    async AllPost(data) {
        try {
            const headers = {
                'Content-Type': 'application/json'
            };
            const jsonData = JSON.stringify(data);
            const response = await axiosInstance.post(`/show/postTimeLine`, jsonData, {headers});
            return response.data;
        } catch (error) {
            console.error("Error creating class:", error);
            throw error; // Rethrow the error for handling at the calling site
        }
      }
      

      async AllPostInUser(data) {
        try {
            const headers = {
                'Content-Type': 'application/json'
            };
            const jsonData = JSON.stringify(data);
            const response = await axiosInstance.post(`/show/studentPost`, jsonData, {headers});
            return response.data;
        } catch (error) {
            console.error("Error creating class:", error);
            throw error; // Rethrow the error for handling at the calling site
        }
      }


      async deletePost(data) {
        try {
            const headers = {
                'Content-Type': 'application/json'
            };
            const jsonData = JSON.stringify(data);
            const response = await axiosInstance.post(`/delete/studentPost`, jsonData, {headers});
            return response.data;
        } catch (error) {
            console.error("Error creating class:", error);
            throw error; // Rethrow the error for handling at the calling site
        }
      }


}

export default new PostModel();