
import React, { useEffect, useState } from 'react'
import { Card, Row, Container, CardHeader, Col, CardBody, CardTitle, NavItem, TabContent, NavLink, Nav, CardText, Button,    Table,
  FormGroup,
  FormText,
  Form,
  Input,
  Label,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,TabPane, ListGroup, ListGroupItem } from 'reactstrap'
  import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import post_modal from 'models/post_modal';
import { helper } from 'lib/helper';
import Avatar from "../../../assets/img/Avatar.jpeg"


const BasicDetails = ({data}) => {


  const [dataList, setDataList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(20);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const parameters = {};
  for (let [key, value] of searchParams) {
    parameters[key] = value;
  }

  const parmas = useParams()
  const getListData = async () => {
 
    const data = { email: parmas.pid };
   
    await post_modal
      .AllPostInUser(data)
      .then((result) => {
    
        if (result) {
          setDataList(result.data.post);
          setCurrentPage(result?.meta?.current_page);
          setPerPage(result?.meta?.per_page);
        }
      })
      .catch((error) => {
        helper.sweetalert.toast(error.response?.data?.message, "warning");
      });
  };

  useEffect(() => {
    getListData();
  }, [location]);

  console.log(dataList,"dataList")


  return (
    <div>
          <div style={{ overflowX: 'auto' }}>
          <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                    <th scope="col">#</th>
                  
                      <th scope="col">TITLE</th>
                      <th scope="col"> Description</th>
                      <th scope="col">Media</th>
                      <th scope="col">Created Date/time</th>
                    </tr>
                  </thead>

                  <tbody>
                  {dataList?.map((item, index) => {
                
                return (
                  <tr key={item._id}>
                    <td>{index + 1}</td>
                    <td>{item?.post_name}</td>
                    <td>{item?.about}</td>
                   
                    <td className="p-0 pl-4">
                            {" "}
                            <img
                              style={{
                                width: "40px",
                                borderRadius: "10%",
                                height: "40px",
                              }}
                              // src={`https://cliply.co/wp-content/uploads/2020/09/442008571_ARTIST_AVATAR_3D_400px.gif`}
                              src={  item.media || Avatar}
                              alt="profileimage"
                            />
                          </td>
                    <td>{item?.created_at}</td>
                 
                  </tr>
                );
              })}
                   
                  </tbody>
                </Table>
                    </div>
                    <br />
              
    </div>
  )
}

export default BasicDetails
