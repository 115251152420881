import { helper } from "lib/helper";
import post_modal from "models/post_modal";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  Badge,
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Table,
  Container,
  Row,
  Input,
  Button,
  UncontrolledTooltip,
  Spinner,
} from "reactstrap";
import AddModal from "views/components/AddModal";
// core components
import TablePagination from "views/components/TablePagination";

import { Link } from "react-router-dom";
import Avatar from "../../assets/img/Avatar.jpeg";
import Swal from "sweetalert2";

const Post = () => {
  const [modal, setModal] = useState(false);

  const toggleModal = (modalName) => {
    setModal(!modal);
  };

  const [isLoading, setIsLoading] = useState(true);
  const [dataList, setDataList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(20);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const parameters = {};
  for (let [key, value] of searchParams) {
    parameters[key] = value;
  }

  const handlePageChange = (curPage) => {
    setCurrentPage(curPage);
  };
  const [search, setSearch] = useState("");
  const storedUserInfo = JSON.parse(sessionStorage.getItem("userinfo"));
  const getListData = async () => {
    const data = {
      email: storedUserInfo?.email,
      pageNo: currentPage,
      limit: 10,
      search: search,
    };
    setIsLoading(true);
    await post_modal
      .AllPost(data)
      .then((result) => {
        setIsLoading(false);
        if (result) {
          setDataList(result);
          setCurrentPage(result?.meta?.current_page);
          setPerPage(result?.meta?.per_page);
        }
      })
      .catch((error) => {
        helper.sweetalert.toast(error.response?.data?.message, "warning");
      });
  };

  useEffect(() => {
    getListData();
  }, [location, currentPage, search]);

  const handleDel = async (delId) => {
    const data = {
      email: storedUserInfo?.email,
      role: "admin",
      postId: delId,
    };
    Swal.fire({
      title: "Are you sure you want to delete?",
      icon: "warning",
      showCancelButton: true,
      showCloseButton: true,
      cancelButtonColor: "#6c757d",
      confirmButtonColor: "#dc3545",
      cancelButtonText: "Cancel",
      confirmButtonText: "Delete",
      allowOutsideClick: false,
      allowEscapeKey: false,
      reverseButtons: true,
      focusCancel: true,
      customClass: {
        header: "text-danger", // Adding custom class to header for cancel icon color
      },
      buttons: {
        cancel: {
          text: "Cancel",
          value: null, // Set value to null for default selection
        },
        confirm: {
          text: "Delete",
          value: true,
        },
      },
    }).then((result) => {
      if (result.isConfirmed) {
        post_modal.deletePost(data).then((res) => {
          helper.sweetalert.toast(res.msg);
          getListData();
        });
      }
    });
  };

  console.log(dataList, "dataListposth");

  return (
    <>
      <Container className="pt-7">
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0 d-flex justify-content-between ">
                <h3 className="mb-0">All Post</h3>

                <div className="d-flex flex-row">
                  {/* <i class="fa-solid fa-magnifying-glass"></i> */}
                  <Input
                    style={{ width: "290px" }}
                    className="mr-md-4 mb-2 mb-md-0 ml-md-4"
                    type="text"
                    placeholder="Search"
                    size="sm"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </div>
              </CardHeader>
              {isLoading ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Spinner></Spinner>
                </div>
              ) : (
                <div>
                  <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-light">
                      <tr>
                        <th>#</th>
                        <th scope="col">User Name</th>
                        <th scope="col">Email</th>
                        <th scope="col">TITLE</th>
                        <th scope="col"> Description</th>
                        <th scope="col">Media</th>
                        <th scope="col">Created Date/time</th>
                        <th>Action</th>
                      </tr>
                    </thead>

                    <tbody>
                      {dataList?.data?.length > 0 ? (
                        dataList?.data?.map((item, index) => {
                          return (
                            <tr key={item._id}>
                              <td>{(currentPage - 1) * perPage + index + 1}</td>
                              <td>{item?.first_name || "-"}</td>
                              <td>{item?.email || "-"}</td>
                              <td>{item?.post_name || "-"}</td>
                              <td>{item?.about || "-"}</td>
                              <td className="p-0 pl-4">
                                {" "}
                                {item.media ===
                                "https://sklinker.ablive.in/" ? (
                                  <span></span>
                                ) : (
                                  <a
                                    className="  mr-3"
                                    href={item?.media}
                                    target="_blank"
                                  >
                                    <img
                                      style={{
                                        width: "40px",
                                        borderRadius: "10%",
                                        height: "40px",
                                      }}
                                      // src={`https://cliply.co/wp-content/uploads/2020/09/442008571_ARTIST_AVATAR_3D_400px.gif`}
                                      src={item?.media || "-"}
                                    />
                                  </a>
                                )}
                              </td>
                              <td>{item?.created_at || "-"}</td>
                              <td
                                className="p-0 pl-4"
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  // justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <UncontrolledTooltip
                                  placement="top"
                                  target="userTooltip"
                                >
                                  Delete
                                </UncontrolledTooltip>
                                <p
                                  style={{
                                    margin: "0 8px",
                                    fontSize: "15px",
                                    color: "red",
                                    marginTop: "10px",
                                    // backgroundColor: "#f5f5f5",
                                    display: "inline-block",
                                    cursor: "pointer",
                                    // padding: "5px", // Optional: Add padding for better appearance
                                    borderRadius: "20%", // Optional: Make it a circle
                                  }}
                                  onClick={() => handleDel(item.id)}
                                  id="userTooltip"
                                >
                                  <i className="fas fa-trash"></i>
                                </p>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr style={{ textAlign: "center" }}>
                          <td colSpan="6" style={{ verticalAlign: "middle" }}>
                            No data found
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                  <CardFooter className="py-4">
                    <TablePagination
                      totalPages={parseInt(dataList?.meta?.total_page)}
                      currentPage={parseInt(dataList?.meta?.current_page)}
                      pageUrl="/admin/post?"
                      onPageChange={handlePageChange}
                    />
                  </CardFooter>
                </div>
              )}
            </Card>
          </div>
        </Row>

        <AddModal
          modal={modal}
          Name="Add6"
          toggle={toggleModal}
          title="Add Fare management"
        />
      </Container>
    </>
  );
};

export default Post;
