import React, { useState, useEffect, useRef } from "react";
import {
  Card,
  Row,
  Container,
  CardHeader,
  Col,
  CardBody,
  CardTitle,
  NavItem,
  TabContent,
  NavLink,
  Nav,
  CardText,
  Button,
  Table,
  FormGroup,
  FormText,
  Form,
  Input,
  Label,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  TabPane,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Dropdown,
  CardFooter,
  UncontrolledTooltip,
  Spinner,
} from "reactstrap";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import tutorsModal from "models/tutors.modal";
import DeviceUUID from "device-uuid";
import { helper } from "lib/helper";
import SubAdminModal from "models/user.modal";
import moment from "moment";
import levelModal from "models/level.modal";
import skillCategoryModal from "models/skillCategory.modal";
import TablePagination from "views/components/TablePagination";
import Swal from "sweetalert2";

const Skillcategorys = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [dataList, setDataList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(20);
  const parmas = useParams();
  const [search, setSearch] = useState("");
  const location = useLocation();
  const handlePageChange = (curPage) => {
    setCurrentPage(curPage);
  };
  const storedUserInfo = JSON.parse(sessionStorage.getItem('userinfo'));

  const getListDataCategory = async () => {
    setIsLoading(true);
    const data = {
      email: storedUserInfo?.email,
      role: "admin",
      pageNo: currentPage,
      limit: 10,
      search: search,
    };

    await skillCategoryModal
      .SkillCategory(data)
      .then((result) => {
        setIsLoading(false);
        if (result) {
          setDataList(result);
          setCurrentPage(result?.meta?.current_page);
          setPerPage(result?.meta?.per_page);
        }
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  console.log(dataList, "dataListskill");

  useEffect(() => {
    if (parmas.mid != "") {
      getListDataCategory();
    }
  }, [location, currentPage, search]);

  console.log(dataList, "dataListlevel");
  // const du = new DeviceUUID();
  // const uuid = du.get();
  const handleDownload = (url) => {
    const newWindow = window.open(url, "_blank");
    if (newWindow) {
      newWindow.focus();
    } else {
      console.error("Popup blocked. Please enable popups and try again.");
    }
  };
  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  const [modal1, setModal1] = useState(false);
  const toggle1 = () => setModal1(!modal1);

  const [modal2, setModal2] = useState(false);
  const toggle2 = () => setModal2(!modal2);

  const [ListLevel, setListLevel] = useState();
  const [skillId, setSkillId] = useState("");

  const [selectuser, setSelectdUser] = useState("");
  const interViewUser = (id) => {
    setSelectdUser(id);
  };
  console.log(skillId, "skillId");

  const [addsLoadig, setAddsLoadig] = useState(false);
  const navigate = useNavigate();

  const [meetingDate, setMeetingDate] = useState("");
  const handleDateChange = (event) => {
    const { value } = event.target;
    setMeetingDate(value); // Update meetingDate state with the selected date
  };
  const formattedDate = moment(meetingDate).format("DD/MM/YYYY");
  const [priceStart, setPriceStart] = useState(""); // State to store start time
  const [priceUpto, setPriceUpTO] = useState(""); // State to store end time
  const [name, setName] = useState();

  // Function to handle changes in the start time input
  const handlepriceStart = (event) => {
    const { value } = event.target;
    setPriceStart(value); // Update startTime state with the selected time
  };

  // Function to handle changes in the end time input
  const handlePriceUpto = (event) => {
    const { value } = event.target;
    setPriceUpTO(value); // Update endTime state with the selected time
  };

  const handleName = (event) => {
    const { value } = event.target;
    setName(value); // Update endTime state with the selected time
  };

  const handleSubmit = (event) => {
    setAddsLoadig(true);
    event.preventDefault();
    const data = {
      email: storedUserInfo?.email,
      role: "admin",
      categoryName: name,
    };
    skillCategoryModal
      .SkillAddCategory(data)
      .then((response) => {
        getListDataCategory();
        helper.sweetalert.toast(response.message, "success");
        toggle();
        setAddsLoadig(false);
      })
      .catch((error) => {
        setAddsLoadig(false);
      });
  };

  const [skillCategoryName, setSkillCategoryName] = useState("");
  const handleupdateName = (event) => {
    const { value } = event.target;
    setSkillCategoryName(value); // Update endTime state with the selected time
  };

  useEffect(() => {
    // Set default values when the component mounts
    if (skillId) {
      setSkillCategoryName(skillId?.category_name);
    }
  }, [skillId]);

  const handleSubmitEditSkillCategory = (event) => {
    setAddsLoadig(true);
    event.preventDefault();

    const data = {
      email: storedUserInfo?.email,
      role: "admin",
      categoryName: skillCategoryName,
      categoryId: skillId?.id,
    };

    skillCategoryModal
      .SkillEditCategory(data)
      .then((response) => {
        getListDataCategory();
        helper.sweetalert.toast(response.msg, "success");
        toggle1();
        setAddsLoadig(false);
      })
      .catch((error) => {
        helper.sweetalert.toast(error.response.data.message, "error");
        console.log(error, "error");
        setAddsLoadig(false);
      });
  };

  const handleDel = async (id, name) => {
    const data = {
      email: storedUserInfo?.email,
      role: "admin",
      categoryId: id,
      // skillName: name,
    };
    Swal.fire({
      title: "Are you sure you want to delete?",
      icon: "warning",
      showCancelButton: true,
      showCloseButton: true,
      cancelButtonColor: "#6c757d",
      confirmButtonColor: "#dc3545",
      cancelButtonText: "Cancel",
      confirmButtonText: "Delete",
      allowOutsideClick: false,
      allowEscapeKey: false,
      reverseButtons: true,
      focusCancel: true,
      customClass: {
        header: "text-danger", // Adding custom class to header for cancel icon color
      },
      buttons: {
        cancel: {
          text: "Cancel",
          value: null, // Set value to null for default selection
        },
        confirm: {
          text: "Delete",
          value: true,
        },
      },
    })
      .then((result) => {
        if (result.isConfirmed) {
          skillCategoryModal.SkillDeleteCategory(data).then((res) => {
            helper.sweetalert.toast(res.msg);
            getListDataCategory();
          });
        }
      })
      .catch((error) => {
        helper.sweetalert.toast(error.response?.data?.message, "warning");
      });
  };

  return (
    <Container className="pt-7">
      <Row>
        <div className="col">
          <Card className="shadow">
            <CardHeader className="border-0 d-flex justify-content-between ">
              <h3 className="mb-0">All Skill</h3>

              <div className="d-flex flex-row">
                {/* <i class="fa-solid fa-magnifying-glass"></i> */}
                <Input
                  style={{ width: "290px" }}
                  className="mr-md-4 mb-2 mb-md-0 ml-md-4"
                  type="text"
                  placeholder="Search Skill Name"
                  size="sm"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
                <Button
                  size="sm"
                  onClick={toggle}
                  color="primary"
                  style={{ width: "150px" }}
                >
                  + Add Skill
                </Button>
              </div>
            </CardHeader>

            {isLoading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Spinner></Spinner>
              </div>
            ) : (
              <div>
                <Table className="align-items-center table-flush " responsive>
                  <thead>
                    <tr style={{ background: "#F6F9FC" }}>
                      <th>#</th>
                      <th>Skill Name</th>

                      <th>Action</th>
                    </tr>
                  </thead>

                  {!isLoading && (
                    <tbody>
                      {dataList?.data?.length > 0 ? (
                        dataList?.data?.map((item, index) => {
                          // let currentDate = new Date(item.createdAt);
                          // let formattedDate = currentDate
                          //   .toISOString()
                          //   .substring(0, 10);
                          return (
                            <tr
                              className="vertical-align-middle"
                              style={{
                                cursor: "pointer",
                                verticalAlign: "middle",
                              }}
                            >
                              <td>{(currentPage - 1) * perPage + index + 1}</td>
                              <td>{item.category_name || '-'}</td>

                              <td
                                className="p-0 pl-4"
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  // justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <UncontrolledTooltip
                                  placement="top"
                                  target="eyeTooltip"
                                >
                                  edit
                                </UncontrolledTooltip>
                                <p
                                  style={{
                                    margin: "0 5px",
                                    fontSize: "15px",
                                    color: "blue",
                                    marginTop: "10px",
                                    display: "inline-block",
                                    // padding: "5px", // Optional: Add padding for better appearance
                                    borderRadius: "30%", // Optional: Make it a circle
                                  }}
                                  id="eyeTooltip"
                                  onClick={() => {
                                    setSkillId(item);
                                    toggle1();
                                  }}
                                  // tag={Link}
                                >
                                  <i className="fa-solid fa-pen-to-square"></i>
                                </p>

                                <UncontrolledTooltip
                                  placement="top"
                                  target="userTooltip"
                                >
                                  Delete
                                </UncontrolledTooltip>
                                <p
                                  style={{
                                    margin: "0 8px",
                                    fontSize: "15px",
                                    color: "red",
                                    marginTop: "10px",
                                    // backgroundColor: "#f5f5f5",
                                    display: "inline-block",
                                    // padding: "5px", // Optional: Add padding for better appearance
                                    borderRadius: "20%", // Optional: Make it a circle
                                  }}
                                  onClick={() =>
                                    handleDel(item.id, item.category_name)
                                  }
                                  id="userTooltip"
                                >
                                  <i className="fas fa-trash"></i>
                                </p>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr style={{ textAlign: "center" }}>
                          <td colSpan="6" style={{ verticalAlign: "middle" }}>
                            No data found
                          </td>
                        </tr>
                      )}
                    </tbody>
                  )}
                </Table>
                <CardFooter className="py-4">
                  <TablePagination
                    totalPages={parseInt(dataList?.meta?.total_page)}
                    currentPage={parseInt(dataList?.meta?.current_page)}
                    pageUrl="/admin/skillcategorys?"
                    onPageChange={handlePageChange}
                  />
                </CardFooter>
              </div>
            )}
          </Card>
        </div>
      </Row>

      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>
          <h4>Add Skill</h4>
        </ModalHeader>
        <ModalBody>
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col md={12}>
                <FormGroup>
                  <Label for="exampleEmail">Skill Name</Label>
                  <Input
                    id="forType"
                    type="text"
                    onChange={handleName}
                    value={name}
                    placeholder="Enter Skill Name"
                  ></Input>
                </FormGroup>
              </Col>
            </Row>
          </Form>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "20px",
            }}
          >
            <Button color="secondary" onClick={toggle}>
              Cancel
            </Button>
            {/* 
                      {addsLoadig ? (
                        <Loader color={"blue"} />
                      ) : ( */}
            <Button
              color="primary"
              type="submit"
              style={{ float: "right", marginLeft: "5%" }}
              onClick={handleSubmit}
            >
              Save
            </Button>
          </div>
        </ModalBody>
      </Modal>

      <Modal isOpen={modal1} toggle={toggle1}>
        <ModalHeader toggle={toggle1}>
          <h4>Edit Skill</h4>
        </ModalHeader>
        <ModalBody>
          <Form onSubmit={handleSubmitEditSkillCategory}>
            <Row>
              <Col md={12}>
                <FormGroup>
                  <Label for="exampleEmail">Skill Name</Label>
                  <Input
                    id="forType"
                    type="text"
                    onChange={handleupdateName}
                    value={skillCategoryName}
                    placeholder="Enter Skill Name"
                  ></Input>
                </FormGroup>
              </Col>
            </Row>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "20px",
              }}
            >
              <Button color="secondary" onClick={toggle1}>
                Cancel
              </Button>
              {/* 
                      {addsLoadig ? (
                        <Loader color={"blue"} />
                      ) : ( */}
              <Button
                color="primary"
                type="submit"
                style={{ float: "right", marginLeft: "5%" }}
              >
                Save
              </Button>
            </div>
          </Form>
        </ModalBody>
      </Modal>
    </Container>
  );
};

export default Skillcategorys;
