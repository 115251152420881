import axiosInstance from "../lib/axiosInstance";

const Parentmodel = class {
  constructor() {
    this.baseUrl = "";
  }

 
  async Parentlist(query=[])
  {
      return (await axiosInstance.post(`/show/admin/AllUser`)).data;
  }

  async AllUser(data) {
    try {
        const headers = {
            'Content-Type': 'application/json'
        };
        const jsonData = JSON.stringify(data);
        const response = await axiosInstance.post(`/show/admin/AllUser`, jsonData, {headers});
        return response.data;
    } catch (error) {
        console.error("Error creating class:", error);
        throw error; // Rethrow the error for handling at the calling site
    }
  }


  async userSkill(data) {
    try {
        const headers = {
            'Content-Type': 'application/json'
        };
        const jsonData = JSON.stringify(data);
        const response = await axiosInstance.post(`/show/studentSkillBySkillType`, jsonData, {headers});
        return response.data;
    } catch (error) {
        console.error("Error creating class:", error);
        throw error; // Rethrow the error for handling at the calling site
    }
  }
  
  async UserData(data) {
    try {
        const headers = {
            'Content-Type': 'application/json'
        };
        const jsonData = JSON.stringify(data);
        const response = await axiosInstance.post(`/show/userByEmail`, jsonData, {headers});
        return response.data;
    } catch (error) {
        console.error("Error creating class:", error);
        throw error; // Rethrow the error for handling at the calling site
    }
  }

  
  async AllConnectionDetails(data) {
    try {
        const headers = {
            'Content-Type': 'application/json'
        };
        const jsonData = JSON.stringify(data);
        const response = await axiosInstance.post(`/user/connectionDetails`, jsonData, {headers});
        return response.data;
    } catch (error) {
        console.error("Error creating class:", error);
        throw error; // Rethrow the error for handling at the calling site
    }
  }
  

  async AllChatDetails(data) {
    try {
        const headers = {
            'Content-Type': 'application/json'
        };
        const jsonData = JSON.stringify(data);
        const response = await axiosInstance.post(`get/admin/chatHistory`, jsonData, {headers});
        return response.data;
    } catch (error) {
        console.error("Error creating class:", error);
        throw error; // Rethrow the error for handling at the calling site
    }
  }


  async UserDelete(data) {
    try {
        const headers = {
            'Content-Type': 'application/json'
        };
        const jsonData = JSON.stringify(data);
        const response = await axiosInstance.post(`delete/admin/deleteUserByEmail`, jsonData, {headers});
        return response.data;
    } catch (error) {
        console.error("Error creating class:", error);
        throw error; // Rethrow the error for handling at the calling site
    }
  }
  
  

  async ParentlistallData(query=[])
  {
      return (await axiosInstance.get(`${this.baseUrl}??page=1&size=100`)).data;
  }

  async listbyParentid(data = []) {
    return (
      await axiosInstance.get(`${this.baseUrl}?id=${data}`)
    );
  }
  async listbyParentidStudentGet(data = []) {
    return (
      await axiosInstance.get(`/student/?pid=${data}`)
    );
  }
  async listbyParentidStudentSubjectGet(id = []) {
    return (
      await axiosInstance.get(`/stsubjects/?id=${id}`)
    );
  }
  async removeParent(data = {}) {
    return await axiosInstance.delete(this.baseUrl, { data: data });
  }

  async createParent(data = []) {
    return await axiosInstance.post(this.baseUrl, data);
  }

  async editParent(data = []) {
    return await axiosInstance.put(this.baseUrl, data);
  }
 
  async ParentClasslist(id = []) {
    return (
        await axiosInstance.get(`/c-class/?parent=${id}`)
      // await axiosInstance.get(`${this.baseUrl}`,{params:data})
    );
  }
  
};

export default new Parentmodel();
