import { Link, useLocation } from "react-router-dom";
// reactstrap components
import { Navbar, Nav, Container } from "reactstrap";
import UserProfileMenu from "components/UserProfileMenu";

const AdminNavbar = (props) => {

  const location = useLocation()
  return (
    <>
      <Navbar className="navbar-top navbar-light" expand="md" id="navbar-main"    >

        <Container fluid>
          <Link
            className="h4 mb-0 text-uppercase d-none d-lg-inline-block color-red"
            to="/"
          >
            
            { location.pathname === "/admin/index" && props.brandText }
          </Link>      

                 
            <Nav className="align-items-center d-none d-md-flex" navbar>
          
              <UserProfileMenu />
            </Nav>
        
        </Container>
      </Navbar>
    </>
  );
};

export default AdminNavbar;
