import React from "react";
// import { Card, CardHeader, Container, Row } from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Container,
  FormGroup,
  FormText,
  Form,
  Input,
  Label,
  Row,
  Col,
  
} from "reactstrap";
const AddStudent = () => {
  return (
    <>
      <Container className="pt-7">
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0 d-flex justify-content-between ">
                <h3 className="mb-0">Add Student</h3>
              </CardHeader>
              <CardBody>
              <Form>
                    <Row>
                      <Col md={6}>
                        <FormGroup>
                          <Label for="exampleEmail">Question</Label>
                          <Input
                            style={{
                              border: "1px solid lightgrey",
                              padding: "6px 10px",
                              borderRadius: "6px",
                            }}
                            id="name"
                            name="question"
                            placeholder="Enter Question"
                          />
                        </FormGroup>
                      </Col>

                      <Col md={6}>
                        <FormGroup>
                          <Label for="exampleEmail">Priority</Label>
                          <Input id="Priority" name="priority" type="number" />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col md={6}>
                        <FormGroup>
                          <Label for="exampleEmail">Answer</Label>
                          <Input
                            id="type"
                            name="answer"
                            type="textarea"
                            placeholder="Enter Answer"
                          />
                        </FormGroup>
                      </Col>

                      <Col md={6}>
                        <FormGroup>
                          <Label for="exampleEmail">Type</Label>
                          <Input id="forType" name="forType" type="select">
                            <option value="driver">select option</option>
                            <option value="user">Tutor</option>
                            <option value="both">Student</option>
                          </Input>
                        </FormGroup>
                      </Col>
                    </Row>

                    {/* <Row>
                <Col md={6}>
                  <FormGroup>
                    <Label for="exampleEmail">Added Date</Label>
                    <Input id="contact" name="contact" type="number" />
                  </FormGroup>
                </Col>

                <Col md={6}>
                  <FormGroup>
                    <Label for="exampleEmail">Status</Label>
                    <Input id="status" name="status" type="select">
                      <option value="">Activate</option>
                      <option value="option1">Deactivate</option>
                      <option value="option2">Pending</option>
                    </Input>
                  </FormGroup>
                </Col>
              </Row> */}

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Button color="secondary" to="/admin/student" tag={Link}>
                        Cancel
                      </Button>
{/* 
                      {addsLoadig ? (
                        <Loader color={"blue"} />
                      ) : ( */}
                        <Button
                          color="primary"
                          type="submit"
                          style={{ float: "right", marginLeft: "5%" }}
                        >
                          Save
                        </Button>
                      {/* )} */}
                    </div>
                  </Form>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};



export default AddStudent