import React, { useEffect, useState } from "react";
// import styles from './style.module.css';
import "./style.css";
import logo from "../../assets/img/logo.svg";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Image,
  InputGroup,
  ListGroup,
  Navbar,
  Row,
} from "react-bootstrap";
import avatar3 from "../../assets/img/Avatar3.svg";
import { MdOutlinePersonOutline } from "react-icons/md";
import { FiHome } from "react-icons/fi";
import { FiZap } from "react-icons/fi";
import { MdLogout } from "react-icons/md";
import { FiMinusCircle } from "react-icons/fi";
import { MdEdit } from "react-icons/md";
import { FaPlus } from "react-icons/fa6";
import { SiPluscodes } from "react-icons/si";
import { IoMdSend } from "react-icons/io";
import parentsModal from "models/parents.modal";
import { useLocation, useParams } from "react-router-dom";
import AllChat from "./AllChat";

const Messages = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [isLoading, setIsLoading] = useState(true);
  const [dataList, setDataList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const parameters = {};
  for (let [key, value] of searchParams) {
    parameters[key] = value;
  }

  const parmas = useParams();
  const getListData = async () => {
    const data = {
      email: parmas.pid,
    };
    setIsLoading(true);
    await parentsModal
      .AllConnectionDetails(data)
      .then((result) => {
        if (result) {
          setIsLoading(false);
          setDataList(result?.data);
          setCurrentPage(result?.meta?.current_page);
        }
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getListData();
  }, [location]);

  const handleItemClick = (item) => {
    setListClickEmail(item);
    
  };


  const [listClickEmail, setListClickEmail] = useState("");
  const [chatHistory, setChatHistory] = useState([]);
  const getListDataMessageChat = async () => {
    const data = {
      email: listClickEmail?.email,
    };
    setIsLoading(true);
    await parentsModal
      .AllChatDetails(data)
      .then((result) => {
        if (result) {
          setIsLoading(false);
          setChatHistory(result?.data);
          setCurrentPage(result?.meta?.current_page);
        }
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (!listClickEmail == "") {
      getListDataMessageChat();
    }
  }, [listClickEmail]);

  console.log(chatHistory, "chatHistory");
  return (
    <div>
      <>
        <Container fluid>
          <Row className="p-0">
            {/* Left Sidebar - Users */}
            <Col md={3} className="left-sidebar">
              <h4>Messages</h4>
              <ListGroup variant="flush" className="p-0">
                {dataList?.map((item, index) => {
                  return (
                    <ListGroup.Item
                          className="p-0"
                          key={`users${index}`}
                          onClick={() => handleItemClick(item)}
                          style={{ cursor: "pointer" }}
                        >
                          <div className="d-flex align-items-center">
                            <div>
                              <img
                                src={item.image_path || avatar3}
                                style={{
                                  height: "30px",
                                  width: "30px",
                                  borderRadius: "50%",
                                }}
                                alt=""
                              />
                            </div>
                            <div className="mt-3">
                              <div>
                                <span
                                  style={{
                                    fontSize: "14px",
                                    marginLeft: "5px",
                                  }}
                                >
                                  {item.first_name}
                                </span>
                              </div>
                              <p
                                style={{ fontSize: "12px", marginLeft: "5px" }}
                              >
                                {/* Today 10:11 am */}
                              </p>
                            </div>
                          </div>
                        </ListGroup.Item> 
                  );
                })}
              </ListGroup>
            </Col>
            {/* Right Sidebar - Chats and Input */}
            {
              listClickEmail && 
              <Col md={9} className="right-sidebar">
             <Navbar
                        style={{
                          boxShadow: "0 2px 3px -1px rgba(0, 0, 0, 0.1)",
                        }}
                        className="bg-body-"
                      >
                        <div className="d-flex align-items-center mt-1 ps-1">
                          <div>
                            <img
                              src={listClickEmail?.image_path || avatar3}
                              style={{
                                height: "30px",
                                width: "30px",
                                borderRadius: "50%",
                              }}
                              alt=""
                            />
                          </div>
                          <div className="mt-3">
                            <div>
                              <span
                                style={{ fontSize: "14px", marginLeft: "5px" }}
                              >
                                {listClickEmail?.first_name}
                              </span>
                            </div>
                            <p
                              style={{
                                fontSize: "12px",
                                marginLeft: "5px",
                                color: "green",
                              }}
                            >
                              {/* Online */}
                            </p>
                          </div>
                        </div>
                      </Navbar>
              <div className="m-3 pe-3 left-sidebar">
                        {chatHistory &&
                          chatHistory?.map((content, index) => (
                            <div key={index}>
                              <AllChat
                                messageItem={content?.content}
                                messageTime={content?.time}
                                fromEmail={content?.from}
                                toEmail={content?.to}
                              />
                            </div>
                          ))}
                        {/* <div ref={messagesEndRef} /> */}
                      </div>
              {/* Chat Messages */}
             
            </Col>
            }
            
          </Row>
        </Container>
      </>
    </div>
  );
};

export default Messages;
