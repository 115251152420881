import axiosInstance from "../lib/axiosInstance";

const SkillCategory = class {
  constructor() {
    this.baseUrl = "/showAll";
  }

  async list(data = []) {
    return (
      await axiosInstance.post(`${this.baseUrl}/skillCategory`)
    ).data;
  }

  async AllPost(data = []) {
    return (
      await axiosInstance.get(`/showAll/studentPost/`)
    ).data;
  }


  // async skillNameBycategoryId(data = []) {
  //   return (
  //     await axiosInstance.post(`${this.baseUrl}/skillNameBycategoryId/`,data)
  //   ).data;
  // }
  async SkillCategory(data) {
    try {
        const headers = {
            'Content-Type': 'application/json'
        };
        const jsonData = JSON.stringify(data);
        const response = await axiosInstance.post(`/show/skillCategory`, jsonData, {headers});
        return response.data;
    } catch (error) {
        console.error("Error creating class:", error);
        throw error; // Rethrow the error for handling at the calling site
    }
}

async SkillAddCategory(data) {
    try {
        const headers = {
            'Content-Type': 'application/json'
        };
        const jsonData = JSON.stringify(data);
        const response = await axiosInstance.post(`/add/admin/skillCategory`, jsonData, {headers});
        return response.data;
    } catch (error) {
        console.error("Error creating class:", error);
        throw error; // Rethrow the error for handling at the calling site
    }
}

async SkillDeleteCategory(data) {
    try {
        const headers = {
            'Content-Type': 'application/json'
        };
        const jsonData = JSON.stringify(data);
        const response = await axiosInstance.post(`/delete/admin/skillCategory`, jsonData, {headers});
        return response.data;
    } catch (error) {
        console.error("Error creating class:", error);
        throw error; // Rethrow the error for handling at the calling site
    }
}

async SkillEditCategory(data) {
    try {
        const headers = {
            'Content-Type': 'application/json'
        };
        const jsonData = JSON.stringify(data);
        const response = await axiosInstance.post(`/update/admin/skillCategory`, jsonData, {headers});
        return response.data;
    } catch (error) {
        console.error("Error creating class:", error);
        throw error; // Rethrow the error for handling at the calling site
    }
}





async newSkill(data) {
  try {
      const headers = {
          'Content-Type': 'application/json'
      };
      const jsonData = JSON.stringify(data);
      const response = await axiosInstance.post(`/show/studentSkill/`, jsonData, {headers});
      return response.data;
  } catch (error) {
      console.error("Error creating class:", error);
      throw error; // Rethrow the error for handling at the calling site
  }
}
async oldSkill(data) {
  try {
      const headers = {
          'Content-Type': 'application/json'
      };
      const jsonData = JSON.stringify(data);
      const response = await axiosInstance.post(`/show/studentSkill/`, jsonData, {headers});
      return response.data;
  } catch (error) {
      console.error("Error creating class:", error);
      throw error; // Rethrow the error for handling at the calling site
  }
}

async addSkillByStudent(data) {
  try {
      const headers = {
          'Content-Type': 'application/json'
      };
      const jsonData = JSON.stringify(data);
      const response = await axiosInstance.post(`/add/studentSkill/`, jsonData, {headers});
      return response.data;
  } catch (error) {
      console.error("Error creating class:", error);
      throw error; // Rethrow the error for handling at the calling site
  }
}




async adduserPost(data = []) {
  return await axiosInstance.post(`/add/studentPost/`, data);
}


 
 
};

export default new SkillCategory();
