import { useNavigate } from "react-router-dom";
import { Card, CardBody, CardTitle, Row, Col } from "reactstrap";
const DashboardKpi = ({ kpiTitle = "", kpiValue = "", kpiIcon="fas fa-chart-bar", iconBakgroundColor = "", navigateUrl="#", textColor = "text-white" }) => {
  const navigate = useNavigate();
  return (
    <>
      <Col lg="6" xl="3">
        <Card className="card-stats mb-4 mb-xl-0 bg-gradient-info" style={{cursor:'pointer'}}>          
          <CardBody onClick={()=> navigate(navigateUrl)}>
            <Row>
              <div className="col">
                <CardTitle
                  // tag="h5"
                  className="text-uppercasemb-0"
                  style={{fontSize:"74%",fontWeight:"bold", color:"white"}}
                >
                  {kpiTitle}
                </CardTitle>
                <span className="h4 font-weight-bold mb-0"  style={{color:"white"}} >
                  {kpiValue}
                </span>
              </div>
              <Col className="col-auto">
                <div className={`icon icon-shape ${iconBakgroundColor} ${textColor} rounded-circle shadow`}>
                  <i className={kpiIcon} />
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    </>
  );
};

export default DashboardKpi