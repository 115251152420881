import axiosInstance from "../lib/axiosInstance";

const Levelmodel = class {
  constructor() {
    this.baseUrl = "/tutor_level/";
  }

  async list(data = []) {
    return (
      await axiosInstance.get(`${this.baseUrl}`)
    ).data;
  }


  async listbyLevelid(data = {}) {
    return await axiosInstance.get(`${this.baseUrl}?id=${data}`);
  }
  async removeLevel(data = {}) {
    return await axiosInstance.delete(`${this.baseUrl}?id=${data}`);
  }



  async createLelvel(data) {

    try {
        const headers = {
            'Content-Type': 'application/json'
        };
        const jsonData = JSON.stringify(data);
        const response = await axiosInstance.post(`${this.baseUrl}`, jsonData, {headers});
        return response.data;
    } catch (error) {
        console.error("Error creating class:", error);
        throw error; // Rethrow the error for handling at the calling site
    }
  }
  
  async editLevel(data) {

    try {
        const headers = {
            'Content-Type': 'application/json'
        };
        const jsonData = JSON.stringify(data);
        const response = await axiosInstance.put(`${this.baseUrl}`, jsonData, {headers});
        return response.data;
    } catch (error) {
        console.error("Error creating class:", error);
        throw error; // Rethrow the error for handling at the calling site
    }
  }
  

  async editlevelnew(data = []) {
    return await axiosInstance.put(this.baseUrl, data);
  }
 
};

export default new Levelmodel();
